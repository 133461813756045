import React, { Component } from "react";
import AuthManager from "../utils/AuthManager";

import General from "../utils/General";

import ManageStoresCards from "./components/manage-stores/ManageStoresCards";

export default class ManageStores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
    };
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
      });
    },
    1000,
    false
  );

  render() {
    const { searchTerm } = this.state;

    let vendorId = AuthManager.currentUser?.company.id;

    return (
      <div className="container">
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <div className="card-title">
              <span className="card-icon">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                      />
                      <path
                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                      />
                    </g>
                  </svg>
                </span>
              </span>
              <h3 className="card-label">All Stores</h3>
            </div>
            <div className="card-toolbar w-xs-100">
              <div className="input-icon mr-3 w-xs-100">
                <input
                  type="text"
                  id="kt_datatable_search_query"
                  className="form-control"
                  placeholder="Search Stores..."
                  onChange={(e) => this._updateSearchTerm(e.target.value)}
                />
                <span>
                  <i className="flaticon2-search-1 text-muted" />
                </span>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <ManageStoresCards searchTerm={searchTerm} vendorId={vendorId} />

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                <a href={`/manage-stores/${vendorId}/add-store`}></a>
                <div className="card card-custom border-dashed gutter-b card-stretch">
                  <a href={`/manage-stores/${vendorId}/add-store`}></a>
                  <div className="card-body text-center pt-4">
                    <a href={`/manage-stores/${vendorId}/add-store`}>
                      <div className="mt-7">
                        <div className="symbol symbol-circle mt-10">
                          <i
                            className="flaticon2-plus-1"
                            style={{ fontSize: 60 }}
                          />
                        </div>
                      </div>
                    </a>
                    <div className="mt-9 mb-6">
                      <a
                        href={`/manage-stores/${vendorId}/add-store`}
                        className="btn btn-clean btn-rounded w-100 mb-3 font-weight-bolder"
                        style={{ fontSize: 25 }}
                      >
                        Add A Store
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
