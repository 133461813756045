import React, { Component } from "react";

import AuthManager from "../../utils/AuthManager";
import Event from "../../utils/Event";
import General from "../../utils/General";

import CustomButton from "./CustomButton";

import { USER_UPDATED } from "../../constants/Events";

export default class UserPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  componentDidMount() {
    Event.on(USER_UPDATED, () =>
      this.setState({
        currentUser: AuthManager.currentUser,
      })
    );
  }

  componentWillUnmount() {
    Event.off(USER_UPDATED);
  }

  _onLogoutPressed() {
    AuthManager.logout().then(() => {
      window.location = "/account";
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <a
            href="javascript:;"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
        </div>

        <div className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y">
          <div className="d-flex align-items-center mt-5">
            <div className="symbol symbol-100 mr-5">
              {currentUser.company?.logo?.original ? (
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url(${currentUser?.company?.logo?.original})`,
                  }}
                />
              ) : (
                <div className="symbol symbol-100">
                  <div
                    className="symbol-label"
                    style={{ width: "100px", height: "100px" }}
                  >
                    <h1 className="m-0" style={{ fontSize: "50px" }}>
                      {General.getInitials(
                        `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`
                      )}
                    </h1>
                  </div>
                </div>
              )}

              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <a
                href="javascript:;"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {currentUser.user?.first_name} {currentUser.user?.last_name}
              </a>
              <div className="text-muted mt-1">
                {" "}
                {currentUser?.company?.title}{" "}
              </div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                              fill="#000000"
                            />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="19.5"
                              cy="17.5"
                              r="2.5"
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {currentUser?.user?.email}
                    </span>
                  </span>
                </a>
                <a
                  href="/account"
                  className="btn btn-sm btn-outline-primary font-weight-bolder py-2 px-5"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5" />

          <div className="navi navi-spacer-x-0 p-0">
            <a href="/settings" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                            fill="#000000"
                          />
                          <circle
                            fill="#000000"
                            opacity="0.3"
                            cx="18.5"
                            cy="5.5"
                            r="2.5"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">My Profile</div>
                  <div className="text-muted">Account settings and more</div>
                </div>
              </div>
            </a>
          </div>

          <div className="separator separator-dashed my-7" />
          <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: 0, width: 0 }}
            />
          </div>
          <div className="ps__rail-y" style={{ top: 0, right: 0, height: 224 }}>
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: 0, height: 126 }}
            />
          </div>
          <div className="row">
            <CustomButton
              buttonClassName="btn btn-primary btn-rounded font-weight-bolder px-9 py-4 ml-4"
              dataWizardType="action-next"
              title="Go To Stripe Dashboard"
              onClick={() => window.open("https://dashboard.stripe.com")}
            />
          </div>
        </div>
      </div>
    );
  }
}
