import React from "react";

import Select from "react-select";

export default class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = this._getState(props);
  }

  compoentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      value: props.value,
      options: props.options.map((option) => ({
        label: this.props.getOptionLabel(option),
        value: this.props.getOptionValue(option),
        _data: option,
      })),
    };
  }

  render() {
    let { value, options } = this.state;

    let props = { ...this.props };
    delete props.options;
    delete props.getOptionLabel;
    delete props.getOptionValue;

    return (
      <Select
        {...props}
        options={options}
        value={options.find((option) => option.value === value)}
        onChange={(option) => {
          this.setState({ value: option.value });
          this.props.onSelected(option._data);
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#F5F5F5",
            primary: "#F5F5F5",
            neutral0: "white",
          },
        })}
        styles={{
          option: (provided, state) => ({
            ...provided,
            color: "black",
          }),
        }}
      />
    );
  }
}
