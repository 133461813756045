import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      type: props.type,
      icon: props.icon,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderExportButton() {
    return (
      <div className="dropdown dropdown-inline mr-4">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bolder"
          onClick={() => this.props.onExportClicked()}
        >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="11"
                  y="3"
                  width="2"
                  height="14"
                  rx="1"
                ></rect>
                <path
                  d="M6.70710678,16.7071068 C6.31658249,17.0976311 5.68341751,17.0976311 5.29289322,16.7071068 C4.90236893,16.3165825 4.90236893,15.6834175 5.29289322,15.2928932 L11.2928932,9.29289322 C11.6714722,8.91431428 12.2810586,8.90106866 12.6757246,9.26284586 L18.6757246,14.7628459 C19.0828436,15.1360383 19.1103465,15.7686056 18.7371541,16.1757246 C18.3639617,16.5828436 17.7313944,16.6103465 17.3242754,16.2371541 L12.0300757,11.3841378 L6.70710678,16.7071068 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  transform="translate(12.000003, 12.999999) scale(1, -1) translate(-12.000003, -12.999999) "
                ></path>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  x="3"
                  y="19"
                  width="18"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
          Export Data
        </button>
      </div>
    );
  }

  render() {
    let { title, icon, type } = this.state;

    return (
      <div className={this.props.className}>
        {this.props.showHeader && (
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <div className="card-title">
              <h3 className="card-label">{title}</h3>
            </div>
            <div className="card-toolbar">
              {this.props.showExport && this._renderExportButton()}
              {this.props.renderToolbar}
            </div>
          </div>
        )}
        {this.props.loading && <LinearProgress />}
        <div className="card-body" style={{ background: "white" }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  className: "card card-custom",
  showExport: true,
  renderToolbar: () => null,
};
