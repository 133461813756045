import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";


import Aside from "./components/Aside";

import EditStoreDetails from "./components/settings/store-settings/EditStoreDetails";
import ManageStaff from "./components/settings/store-settings/ManageStaff";

const TAB_STORE_DETAILS_SETTINGS = {
  title: "Edit Store Details",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z"
        fill="#000000"
      />
      <path
        d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  largeIcon: true,
  description: "Your first store to kick things off",
  component: EditStoreDetails,
};

const TAB_STAFF_SETTINGS = {
  title: "Manage Staff",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />

      <path
        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
        fill="#000000"
        fill-rule="nonzero"
      />
    </g>
  ),
  largeIcon: true,
  description: "Manage your staff",
  component: ManageStaff,
};

const TABS = [TAB_STORE_DETAILS_SETTINGS, TAB_STAFF_SETTINGS];




export class StoreSettings extends Component {
  constructor(props) {
    super(props);

    let tabs = TABS;
    this.state = {
      tabs,
      activeTabIndex: this._getActiveTabIndex(tabs),
      isLoading: false,
    };
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

 
  render() {
    const { tabs, activeTabIndex} = this.state;

    let activeTab = tabs[activeTabIndex];
    let TabComponent = activeTab.component;

    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="first"
                  data-wizard-clickable="false"
                >
                  <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    {" "}
                    <Aside
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onClick={(tab, index) => {
                        this.setState(
                          {
                            activeTabIndex: index,
                          },
                          () => {
                            this.props.history.push(tab.url);
                          }
                        );
                      }}
                    />
                  </div>
                  <TabComponent
                    vendorId={this.props.match.params?.vendorId}
                    venueId={this.props.match.params?.venueId}          
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StoreSettings);
