import React, { Component } from "react";

import Notify from "../../../../utils/Notify";
import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";

import Separator from "../../common/Separator";

import AddNewCompanyMemberModal from "../../modals/AddNewCompanyMemberModal";

export default class ManageStaff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyMembers: [],
    };
  }

  componentDidMount() {
    this._getCompanyMembers();
  }

  _getCompanyMembers() {
    let companyId = this.props.vendorId;

    this.setState({
      isLoading: true,
    });

    Backend.getCompanyMembers(companyId)
      .then((companyMembers) => {
        this.setState({ companyMembers, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _addStaff(companyMemberId, venueId) {
    this.setState({ isLoading: true });

    Backend.addStaff(companyMemberId, venueId)
      .then(() => {
        this._getCompanyMembers();
        Notify.success("Added");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _removeStaff(companyMemberId, venueId) {
    this.setState({ isLoading: true });

    Backend.removeStaff(companyMemberId, venueId)
      .then(() => {
        this._getCompanyMembers();
        Notify.success("Removed");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { companyMembers, showAddNewCompanyMember } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 ml-15">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-12">
              <form className="form" id="kt_form">
                <div
                  className="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h6 className="mb-10 font-weight-bold">
                    Add your store staff information.
                  </h6>
                  <h4 className="mb-10 font-weight-bold">Add existing staff</h4>
                  {companyMembers?.map((companyMember, index) => {
                    let isStaffMember =
                      companyMember.venues.find(
                        (venueId) => venueId == this.props.venueId
                      ) != null;

                    return (
                      <>
                        <div className="form-group row" key={General.uuid()}>
                          <div className="col-md-8 mt-3">
                            <label>
                              {" "}
                              {General.toTitleCase(
                                companyMember?.user.first_name
                              )}{" "}
                              {General.toTitleCase(
                                companyMember?.user.last_name
                              )}
                            </label>
                          </div>
                          <div className="col-md-4 text-right">
                            <span className="switch switch-icon">
                              <label>
                                <input
                                  className="reminder-toggle"
                                  type="checkbox"
                                  checked={isStaffMember}
                                  onChange={(e) => {
                                    let checked = e.target.checked;

                                    if (checked) {
                                      this._addStaff(
                                        companyMember.user.id,
                                        this.props.venueId
                                      );
                                    } else {
                                      this._removeStaff(
                                        companyMember.user.id,
                                        this.props.venueId
                                      );
                                    }
                                  }}
                                />
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <Separator mainTitle="Add new staff" />

                  <div style={{ margin: "0 auto" }}>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold text-uppercase px-9 py-4 ml-21"
                      onClick={() =>
                        this.setState({
                          showAddNewCompanyMember: true,
                        })
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <AddNewCompanyMemberModal
          show={showAddNewCompanyMember}
          vendorId={this.props.vendorId}
          venueId={this.props.venueId}
          onUpdated={(companyMember) => {
            this._getCompanyMembers();

            this.setState({
              showAddNewCompanyMember: false,
            });
          }}
          onHide={() =>
            this.setState({
              showAddNewCompanyMember: false,
            })
          }
        />
      </>
    );
  }
}
