import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";
import Event from "../../../../utils/Event";

import CustomButton from "../../CustomButton";

import { USER_UPDATED } from "../../../../constants/Events";

export default class PasswordSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: General.clone(props.data),
      isLoading: props.isLoading,
    };
  }

  _handlePasswordChange(e) {
    let { data } = this.state;

    const key = e.target.name;
    const value = e.target.value;
    data.user[key] = value;
    this.setState({ data });
  }

  _isPasswordFormValid() {
    const { data } = this.state;

    let { current_password, password, confirm_password } = data.user;

    let isValid = true;
    let error = null;

    if (
      !current_password ||
      current_password === "" ||
      current_password.length < 6
    ) {
      isValid = false;
      error =
        "Please enter your current password. Password must be at least 6 characters.";
    } else if (!password || password === "" || password.length < 6) {
      isValid = false;
      error =
        "Please enter a valid new password. Password must be at least 6 characters.";
    } else if (password !== confirm_password) {
      isValid = false;
      error = "Passwords don't match";
    }

    if (error) {
      Notify.error(error);
    }

    return isValid;
  }

  _updateCompanyMemberAccount() {
    const { data } = this.state;

    let message = "Your password details have been updated successfully.";

    if (!this._isPasswordFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateCompanyMemberAccount(data)
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
        AuthManager.currentUser = data;
        this.props.onUpdated(data);
        Event.emit(USER_UPDATED);
        Notify.success(message);
        window.location.reload()
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 ml-15">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-12">
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_form"
              >
                <div
                  className="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  {" "}
                  <h4 className="mb-10 font-weight-bolder text-dark">
                    Password Settings
                  </h4>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Current Password
                    </label>
                    <div className="col-8 mb-4">
                      <input
                        className="form-control"
                        type="password"
                        name="current_password"
                        onChange={(e) => {
                          this._handlePasswordChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">New Password</label>
                    <div className="col-8 mb-4">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        onChange={(e) => {
                          this._handlePasswordChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Confirm New Password
                    </label>
                    <div className="col-8 mb-4">
                      <input
                        className="form-control"
                        type="password"
                        name="confirm_password"
                        onChange={(e) => {
                          this._handlePasswordChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-4 col-form-label" />
                    <div className="col-9 mb-4 text-right">
                      <CustomButton
                        buttonClassName="btn btn-primary text-uppercase"
                        title="Save Changes"
                        disabled={isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          this._updateCompanyMemberAccount();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div />
                <div />
                <div />
                <div />
                <div />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
