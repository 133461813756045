import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";

import CustomButton from "../../CustomButton";

import AddCategoryModal from "../../modals/AddCategoryModal";

import MenuItemsTable from "../../tables/MenuItemsTable";

export class AddMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { data: {}, firstVenue: {}, selectedCategoryItem: null };
  }

  componentDidMount() {
    this._getFirstVenue();
  }

  onClickTabItem(tab) {
    this.setState({ selectedCategoryItem: tab });
  }

  _getFirstVenue() {
    this.setState({
      isLoading: true,
    });

    Backend.getFirstVenue()
      .then((firstVenue) => {
        this.setState({ firstVenue, isLoading: false }, () => this._getMenu());
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getMenu() {
    const { firstVenue } = this.state;

    let menuId = AuthManager.currentUser?.active_venue ?? firstVenue.id;

    if (menuId) {
      this.setState({
        isLoading: true,
      });

      Backend.getMenu(menuId)
        .then((data) => {
          this.setState({ data, isLoading: false });
        })
        .catch((error) => {
          Notify.error(error.message);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      return;
    }
  }

  _showCategories() {
    let { data, selectedCategoryItem } = this.state;

    if (data?.menu_categories?.length > 0) {
      selectedCategoryItem = data.menu_categories[0]?.category.id;
    }

    return data?.menu_categories?.map((categoryItem) => {
      return (
        <li className="nav-item mt-3" key={categoryItem?.category.id}>
          <a
            onClick={() => {
              this.onClickTabItem(categoryItem?.category?.id);
            }}
            className={`nav-link ${
              selectedCategoryItem === categoryItem?.category?.id
                ? "active"
                : ""
            }`}
            id="wine-tab"
            data-toggle="tab"
            href="wine"
            aria-controls="profile"
          >
            <span className="nav-text">
              {General.toTitleCase(categoryItem?.category?.title)}
            </span>
          </a>
        </li>
      );
    });
  }

  render() {
    let { showAddCategoryModal, data, firstVenue, isLoading } = this.state;

    let categoryMenuItem = data?.menu_categories?.find(
      (categoryItem) =>
        categoryItem?.category.id == this.state.selectedCategoryItem
    );

    let categoryId = categoryMenuItem?.category.id;

    let menuCategoryId = categoryMenuItem?.id;

    let menuId = AuthManager.currentUser?.active_venue ?? firstVenue.id;

    return (
      <>
        <div className="row mt-20">
          <div className="offset-xxl-2 col-xxl-10">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_form"
              >
                <h6 className="mb-0 font-weight-bold">
                  Ok, you’re all set up! Now we just need to give your customers
                  something to buy. Add your menu below and you can start
                  selling.
                </h6>
                <h4 className="mb-4 font-weight-bold text-dark mt-10">
                  Your Menu
                </h4>
                <div className="form-group category-row">
                  <ul
                    className="nav nav-primary nav-pills"
                    id="category-tabs"
                    role="tablist"
                  >
                    {this._showCategories()}

                    <li className="nav-item mt-3 ml-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-xs-100"
                        onClick={() =>
                          this.setState({ showAddCategoryModal: true })
                        }
                      >
                        Add a Category
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="form-group">
                  <h6 className="font-weight-bolder text-muted">
                    Add a category to begin adding menu items
                  </h6>
                </div>
                <div className="form-group">
                  {" "}
                  {this.state.selectedCategoryItem && (
                    <MenuItemsTable
                      menuId={menuId}
                      menuCategoryId={menuCategoryId}
                      categoryId={categoryId}
                    />
                  )}
                </div>{" "}
                <div className="col text-right">
                  <CustomButton
                    buttonClassName="btn secondary-bg font-weight-bold text-uppercase px-9 py-4"
                    title="Submit"
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();

                      this.props.history.push("/");
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <AddCategoryModal
          show={showAddCategoryModal}
          menuId={menuId}
          onUpdated={(category) => {
            this._getMenu();
            this.setState({
              showAddCategoryModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showAddCategoryModal: false,
            })
          }
        />
      </>
    );
  }
}

export default withRouter(AddMenu);
