import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";
import LocationFormat from "../../../utils/LocationFormat";

import BaseTable from "./BaseTable";

import OrdersSubTable from "./OrdersSubTable";

const STATUS_FILTERS = {
  name: {
    api: "statuses",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Accepted",
      value: "accepted",
    },

    {
      label: "Rejected",
      value: "rejected",
    },
  ],
};

const DELIVERY_STATUS_FILTERS = {
  name: {
    api: "delivery_statuses",
    display: "Delivery Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Out For Delivery",
      value: "out_for_delivery",
    },
    {
      label: "Failed",
      value: "failed",
    },
    {
      label: "Returned",
      value: "returned",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ],
};

class PastOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _returnOrder(orderId) {
    this.setState({ isLoading: true });

    Backend.returnOrder(orderId)
      .then((order) => {
        order.delivery_status = "returned";
        this.setState({ order, isLoading: false });
        this.table.current.refresh();
        Notify.success("Order has been returned.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ORDER NO.</strong>,
        id: "id",
        width: 90,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let orderNumber = `${order.id}`;

          if (!orderNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">{orderNumber}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DELIVERY ADDRESS</strong>,
        id: "address",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let customerAddress = order.data.customer_address;

          if (!customerAddress) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {this._renderAddress(customerAddress)}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">CUSTOMER NAME</strong>,
        id: "customer.user.first_name",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let customerName = `${order?.customer?.user?.first_name} ${order?.customer?.user?.last_name}`;

          if (!customerName) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {customerName}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">CUSTOMER PHONE NUMBER</strong>
        ),
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let customerPhoneNumber = order.customer.user?.phone_number;

          let countryCode = order.customer.user.phone_country_code;

          if (!customerPhoneNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {countryCode && countryCode}
                {customerPhoneNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (order.status === "accepted") {
            labelClass = "label-light-success";
            status = "Accepted";
          } else if (order.status === "rejected") {
            labelClass = "label-danger";
            status = "Rejected";
          }

          if (!status) {
            return "-";
          }

          return (
            <span className={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DELIVERY STATUS</strong>,
        id: "delivery_status",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let deliveryStatus = "";

          if (order.delivery_status === "delivered") {
            labelClass = "label-light-success";
            deliveryStatus = "Delivered";
          } else if (order.delivery_status === "pending") {
            labelClass = "label-light-info";
            deliveryStatus = "Pending";
          } else if (order.delivery_status === "failed") {
            labelClass = "label-dark-75";
            deliveryStatus = "Failed";
          } else if (order.delivery_status === "returned") {
            labelClass = "label-danger";
            deliveryStatus = "Returned";
          } else if (order.delivery_status === "out_for_delivery") {
            labelClass = "label-warning";
            deliveryStatus = "Out For Delivery";
          }

          if (!deliveryStatus) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {deliveryStatus}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">DRIVER PHONE NUMBER</strong>
        ),
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let driverPhoneNumber = order.driver?.user.phone_number;

          let countryCode = order.driver?.user.phone_country_code;

          if (!driverPhoneNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {countryCode && countryCode}
                {driverPhoneNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">ITEMS NUMBER</strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let orderedItemsLength = `${order.data.items.length}`;

          if (!orderedItemsLength || orderedItemsLength === []) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {orderedItemsLength}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">COST</strong>,
        id: "price",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let cost = order?.data?.items?.reduce((sum, item) => {
            const updatedPrice =
              sum +
              (item?.price_sale ? item?.price_sale : item?.price) *
                item?.quantity;

            return updatedPrice;
          }, 0);

          if (!cost) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {Currency.format(cost)}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">ORDER DATE / TIME</strong>
        ),
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let createdAt = `${moment(order.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!createdAt) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">{createdAt}</span>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let orderId = order.id;

          return (
            <>
              {order?.delivery_status === "failed" ? (
                <span style={{ width: 130 }}>
                  {" "}
                  <a
                    href="javascript:;"
                    className="btn btn-outline-primary"
                    onClick={() => this._returnOrder(orderId)}
                  >
                    Returned
                  </a>{" "}
                </span>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
  }

  _renderOrdersSubTable(order) {
    return (
      <OrdersSubTable showPaginationTop={false} title={``} order={order} />
    );
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    if (this.props.showOrdersSubTable) {
      tableProps = {
        SubComponent: (row) => this._renderOrdersSubTable(row.original),
      };
    }

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}?statuses=accepted,rejected`}
          noDataMessage={"No past orders found"}
          columns={columns}
          SubComponent={(row) => this._renderOrdersSubTable(row.original)}
          filters={[STATUS_FILTERS, DELIVERY_STATUS_FILTERS]}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

PastOrdersTable.defaultProps = {
  title: "Past Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(PastOrdersTable);
