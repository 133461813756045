import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";
import Notify from "../../../utils/Notify";

export default class PasscodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      passcode: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  render() {
    const { show, passcode, isLoading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
        dialogClassName={"modal-dialog-centered modal-md"}
        className={"sub-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Passcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-1 stripe-modal-message">
            <p className="text-justify">
              Once it is verified you can manage menu items.
            </p>
            <div className="form-group">
              <textarea
                style={{ height: 45, resize: "none" }}
                className="form-control form-control-solid form-control-lg"
                placeholder="Enter Passcode"
                name="passcode"
                value={passcode !==null ? passcode : null}
                onChange={(e) =>
                  this.setState({ passcode: e.target.value })
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => {
                if (!passcode) {
                  Notify.error("Please enter a valid passcode.");
                } else {
                  this.props.onHide();
                }

                this.props.onUpdated(passcode);
              }}
            >
              Verify Password
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  }
}

PasscodeModal.defaultProps = {
  title: "",
};
