import React, { Component } from "react";

import CustomButton from "../../CustomButton";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import ImageUpload from "../../common/ImageUpload";
import AuthManager from "../../../../utils/AuthManager";

export default class AddCompanyBranding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        logo: null,
        featured_image: null,
      },
    };
  }

  componentDidMount() {
    this._getCompanyDetails();
  }

  _getCompanyDetails() {
    let companyId = AuthManager.currentUser.company.id;

    if (companyId) {
      this.setState({
        isLoading: true,
      });

      Backend.getCompanyDetails(companyId)
        .then((data) => {
          this.setState({ data, isLoading: false });
        })
        .catch((error) => {
          Notify.error(error.message);
          this.setState({ isLoading: false });
        });
    } else {
      return;
    }
  }

  _isFormValid() {
    const { data } = this.state;

    const { logo, featured_image } = data;
    let error = null;

    if (!logo) {
      error = "Please upload a logo.";
    } else if (!featured_image) {
      error = "Please upload featured image.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateCompanyDetails() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateCompanyDetails(data)
      .then(() => {
        this._getCompanyDetails();
        this.props.onNextClick();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { data, isLoading } = this.state;

    return (
      <>
        <div className="row mt-20">
          <div className="offset-xxl-2 col-xxl-8">
            <div className="pb-5" data-wizard-type="step-content">
              <h6 className="mb-10 font-weight-bold">
                Show your customers what your company is about. Add your logo
                and some imagery that describes your brand.
              </h6>
              <h4 className="mb-4 font-weight-bold text-dark">
                Company Branding
              </h4>

              <div className="form-group">
                <label>Logo</label>
                <ImageUpload
                  image={data?.logo}
                  hidePreview={false}
                  cropImage={true}
                  aspectRatio={1}
                  onUpdated={(logo) => {
                    data.logo = logo;

                    this.setState({ data });
                  }}
                />
              </div>

              <div className="form-group">
                <label>Featured Images</label>{" "}
                <ImageUpload
                  image={data?.featured_image}
                  hidePreview={false}
                  cropImage={true}
                  aspectRatio={1}
                  onUpdated={(featured_image) => {
                    data.featured_image = featured_image;

                    this.setState({ data });
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between border-top mt-5 mb-8 pt-10">
              <div className="mr-2"></div>
              <div>
                <CustomButton
                  buttonClassName="btn btn-primary font-weight-bolder px-9 py-4"
                  dataWizardType="action-next"
                  title="Continue"
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    this._updateCompanyDetails();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
