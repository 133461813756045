import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import CustomButton from "../CustomButton";

import notificationImage from "../../../assets/media/svg/menu.svg";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class StripeVerificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      company: props.company,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _goToStripeUrl() {
    const { company } = this.state;

    this.setState({
      isLoading: true,
    });

    Backend.getStripeUrl(company)
      .then((stripeData) => {
        this.setState({ stripeData, isLoading: false });

        window.location = stripeData.connect_url;
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { show, isLoading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
        dialogClassName={"modal-dialog-centered modal-md"}
        className={"sub-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete Stripe Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-1 stripe-modal-message">
            <p className="text-justify">
              Please finish setting up Stripe Account. Once it is verified you
              can manage menu items.
            </p>
            <div className="stripe-modal-image">
              <img src={notificationImage} alt="Notification" />
            </div>
          </div>
          <div className="mt-5 py-1 ml-35">
            <CustomButton
              buttonClassName="btn btn-primary btn-rounded font-weight-bolder px-9 py-4 ml-4"
              dataWizardType="action-next"
              title="Complete Stripe Setup"
              disabled={isLoading}
              onClick={() => this._goToStripeUrl()}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

StripeVerificationModal.defaultProps = {
  title: "",
  message: null,
  isLoading: false,
  buttonClassName: "confirm",
  confirmButtonText: "Yes",
  cancelButtonText: "No",
};
