import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";
import Event from "../../../../utils/Event";

import ImageUpload from "../../common/ImageUpload";

import CustomButton from "../../CustomButton";

import { USER_UPDATED } from "../../../../constants/Events";

export default class BrandingSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: General.clone(props.data?.company),
      isLoading: props.isLoading,
    };
  }

  _handleCompanyChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { title, logo, featured_image } = data;

    let error = null;

    if (!title) {
      error = "Please enter a valid title.";
    } else if (!logo) {
      error = "Please upload a logo.";
    } else if (!featured_image) {
      error = "Please upload featured image.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateCompanyDetails() {
    let { data } = this.state;

    let message = "Your company details have been updated successfully.";

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateCompanyDetails(data)
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
        AuthManager.currentUser.company = data;
        this.props.onUpdated(data);
        Event.emit(USER_UPDATED);
        Notify.success(message);
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { data, isLoading } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 ml-15">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-12">
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_form"
              >
                <div className="pb-5" data-wizard-type="step-content">
                  <h4 className="mb-4 font-weight-bold text-dark">
                    Control Your Brand Identity
                  </h4>

                  <div className="form-group row mt-12">
                    <label className="col-4 col-form-label">Company Name</label>
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="text"
                        name="title"
                        value={data?.title}
                        onChange={(e) => {
                          this._handleCompanyChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group mt-8">
                    <label>Company Logo</label>
                    <ImageUpload
                      image={data?.logo}
                      hidePreview={false}
                      cropImage={true}
                      aspectRatio={1}
                      onUpdated={(logo) => {
                        data.logo = logo;

                        this.setState({ data });
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label>Featured Images</label>{" "}
                    <ImageUpload
                      image={data?.featured_image}
                      hidePreview={false}
                      cropImage={true}
                      aspectRatio={1}
                      onUpdated={(featured_image) => {
                        data.featured_image = featured_image;

                        this.setState({ data });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label" />
                  <div className="col-9 mb-4 text-right">
                    <CustomButton
                      buttonClassName="btn btn-primary text-uppercase"
                      title="Save Changes"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        this._updateCompanyDetails();
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
