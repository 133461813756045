import FetchHelper from "./FetchHelper";

import AuthManager from "./AuthManager";

export default class Backend {
  static getVenue(venueId) {
    return FetchHelper.get(`${window.Api.Venues}/${venueId}`);
  }

  static getFirstVenue() {
    return FetchHelper.get(`${window.Api.Venues}`).then(
      (response) => response.results[0]
    );
  }

  static addVenue(venue) {
    let data = { ...venue };
    return FetchHelper.post(`${window.Api.Venues}`, data);
  }

  static editVenue(venue) {
    let data = { ...venue };
    return FetchHelper.patch(`${window.Api.Venues}/${venue.id}`, data);
  }

  static makeVenueActive(activeVenueId) {
    let companyMemberId = AuthManager.currentUser.user.id;

    return FetchHelper.patch(
      `${window.Api.CompanyMembers}/${companyMemberId}`,
      {
        active_venue: activeVenueId,
      }
    );
  }

  static getStripeUrl(company) {
    let returnUrl = window.location.href;

    return FetchHelper.get(
      `${window.Api.StripeConnect}?company_id=${company.id}&return_url=${returnUrl}&refresh_url=${returnUrl}`
    );
  }

  static getCategories() {
    return FetchHelper.get(`${window.Api.Categories}`).then(
      (response) => response.results
    );
  }

  static addCategory(id, data) {
    return FetchHelper.patch(`${window.Api.Menus}/${id}`, data);
  }

  static addMenuCategory(category) {
    let data = { ...category };
    return FetchHelper.post(`${window.Api.MenuCategories}`, data);
  }

  static getMenu(id, passcode) {
    return FetchHelper.get(`${window.Api.Menus}/${id}/categories?passcode=${passcode}`);
  }

  static addMenu(menu) {
    let data = { ...menu };
    return FetchHelper.post(`${window.Api.Menus}`, data);
  }

  static editMenu(menu) {
    let data = { menu };
    return FetchHelper.patch(`${window.Api.Menus}/${menu}`, data);
  }

  static addMenuItem(menuItem) {
    let data = { ...menuItem };
    return FetchHelper.post(`${window.Api.MenuItems}`, data);
  }

  static editMenuItem(menuItem, passcode) {
    let data = { ...menuItem };
    return FetchHelper.patch(`${window.Api.MenuItems}/${menuItem.id}?passcode=${passcode}`, data);
  }

  static removeMenuItem(menuItemId, passcode) {
    return FetchHelper.delete(`${window.Api.MenuItems}/${menuItemId}?passcode=${passcode}`);
  }

  static getStats(venueId) {
    return FetchHelper.get(`${window.Api.Venues}/${venueId}`);
  }

  static getOrders() {
    return FetchHelper.get(
      `${window.Api.Orders}?statuses=pending,looking_for_driver,accepted&delivery_statuses=pending`
    );
  }

  static acceptOrder(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      status: "looking_for_driver",
    });
  }

  static rejectRefundOrder(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      status: "rejected",
    });
  }

  static outForDelivery(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      delivery_status: "out_for_delivery",
    });
  }

  static returnOrder(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      delivery_status: "returned",
    });
  }

  static getStaff(companyId, venueId) {
    return FetchHelper.get(
      `${window.Api.Staff}?company_id=${companyId}&venue_id=${venueId}`
    ).then((response) => response.results);
  }

  static addStaff(companyMemberId, venueId) {
    let data = { company_member: companyMemberId, venue: venueId };

    return FetchHelper.post(`${window.Api.Staff}`, data);
  }

  static removeStaff(companyMemberId, venueId) {
    let data = { company_member_id: companyMemberId, venue_id: venueId };

    return FetchHelper.delete(`${window.Api.Staff}`, data);
  }

  static getCompanyMembers(companyId) {
    return FetchHelper.get(
      `${window.Api.CompanyMembers}?company_id=${companyId}&paginated=false`
    ).then((response) => response.results);
  }

  static addCompanyMember(companyMember) {
    let data = { ...companyMember };
    return FetchHelper.post(`${window.Api.CompanyMembers}`, data);
  }

  static updateCompanyMemberAccount(companyMember) {
    let data = { ...companyMember };
    return FetchHelper.patch(
      `${window.Api.CompanyMembers}/${companyMember.user.id}`,
      data
    );
  }

  static getCompanyDetails(companyId) {
    return FetchHelper.get(`${window.Api.Companies}/${companyId}`);
  }

  static updateCompanyDetails(company) {
    let data = { ...company };

    data.logo = data.logo.id;
    data.featured_image = data.featured_image.id;

    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data);
  }

  static uploadImage(image, type = "favicon") {
    let data = new FormData();

    data.append("file", image, image.name);
    data.append("type", type);

    return FetchHelper.post(window.Api.Images, data, true);
  }
}
