import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import OutstandingOrdersCard from "./OutstandingOrdersCard";

import PlaybackLoadingCard from "../common/PlaybackLoadingCard";

import noContentImg from "../../../assets/media/svg/no-content/no-content.svg";

export default class OutstandingOrdersCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.lazyLoadingList = React.createRef();
  }

  componentDidMount() {
    this.autoRefresh = setInterval(() => {
      this.lazyLoadingList.current.refresh();
    }, 1000 * 30);
  }

  componentWillUnmount() {
    clearInterval(this.autoRefresh);
  }

  _renderPlaceholderCards() {
    return (
      <>
        <PlaybackLoadingCard />
        <PlaybackLoadingCard />
      </>
    );
  }

  _renderNoContent() {
    return (
      <div className="no-content">
        <div className="no-content-section">
          <h3>You currently have no orders...</h3>
          <img src={noContentImg} alt="No Content" />
        </div>
      </div>
    );
  }

  render() {
    let url = `${window.Api.Orders}?statuses=${this.props.statuses}&search_term=${this.props.searchTerm}`;

    if (this.props.deliveryStatuses) {
      url += `&delivery_statuses=${this.props.deliveryStatuses}`;
    }

    return (
      <>
        <LazyLoadingList
          ref={this.lazyLoadingList}
          endpoint={url}
          onItemUpdated={(orders) => {
            this.setState({
              orders,
              isLoading: true,
            });
          }}
          renderItem={(order) => {
            return (
              <OutstandingOrdersCard
                order={order}
                onUpdated={() => this.lazyLoadingList.current.refresh()}
              />
            );
          }}
          renderInitialLoading={() => this._renderPlaceholderCards()}
          renderLoadingMore={() => this._renderPlaceholderCards()}
          renderNoContent={() => this._renderNoContent()}
        />
      </>
    );
  }
}
