import General from "./General";

export default class LocationFormat {
  static fullAddress(location) {
    let address = location.line_1;
    if (location.line_2) {
      address += ", " + location.line_2;
    }
    if (location.line_3) {
      address += ", " + location.line_3;
    }

    address += ", " + location.city;

    if (location.postal_code || location.postal_code != null) {
      address += ", " + location.postal_code;
    }

    return General.toTitleCase(address);
  }
}
