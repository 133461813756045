import React from "react";

import { withRouter } from "react-router-dom";

import BaseTable from "./BaseTable";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";

import AddMenuItemModal from "../modals/AddMenuItemModal";
import EditMenuItemModal from "../modals/EditMenuItemModal";

class MenuItemsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _removeMenuItem(menuItemId) {
    let passcode = this.props.passcode 
  
    this.setState({ isLoading: true });

    Backend.removeMenuItem(menuItemId, passcode)
      .then(() => {
        this.setState({ isLoading: false });
        Notify.success("Menu item has been removed successfully.");
        this.table.current.refresh();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ITEM</strong>,
        id: "item",
        Cell: (rowInfo) => {
          let menuItem = rowInfo.original;

          let menuItemTitle = menuItem.item.title;
          let menuItemDescription = menuItem.item.description;

          let menuItemImage = menuItem.item.image.original;

          if (!menuItemTitle) {
            return "-";
          }
          return (
            <>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-40 flex-shrink-0">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url(${menuItemImage})`,
                    }}
                  />
                </div>
                <div className="ml-2">
                  <div className="text-dark-75 font-weight-bold line-height-sm">
                    {General.toTitleCase(menuItemTitle)}
                  </div>
                  <a
                    href="javascript:;"
                    className="font-size-sm text-dark-50 text-hover-primary"
                  >
                    {menuItemDescription ?? "-"}
                  </a>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">PRICE</strong>,
        id: "price",
        Cell: (rowInfo) => {
          let menuItem = rowInfo.original;

          let price = menuItem.price;

          if (price == null) {
            return "-";
          }
          return (
            <>
              <div>{Currency.format(price)}</div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">SALE PRICE</strong>,
        id: "price",
        Cell: (rowInfo) => {
          let menuItem = rowInfo.original;

          let salePrice = menuItem.price_sale;

          if (salePrice == null) {
            return "-";
          }

          return (
            <>
              <div>{Currency.format(salePrice)}</div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "item",
        Cell: (rowInfo) => {
          let menuItem = rowInfo.original;

          let selectedItem = menuItem;

          let { isLoading } = this.state;

          return (
            <div className="row align-items-right">
              <div className="col-md-6 my-2 my-md-0">
                <button
                  type="button"
                  className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                  onClick={() =>
                    this.setState({
                      selectedItem,
                      showEditMenuItemModal: true,
                    })
                  }
                >
                  Edit
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                  disabled={isLoading}
                  onClick={() => {
                    this._removeMenuItem(selectedItem?.id);
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        },
      },
    ];
  }
  render() {
    let {
      showAddMenuItemModal,
      showEditMenuItemModal,
      selectedItem,
      isLoading,
    } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.MenuItemsV2}?menu_id=${this.props.menuId}&category_id=${this.props.categoryId}&passcode=${this.props.passcode}`}
          noDataMessage={"No menu items found"}
          columns={columns}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={(rowInfo) => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="row align-items-right">
                    <div className="col-md-12 my-2 my-md-0">
                      <div className="dropdown dropdown-inline mr-2">
                        <button
                          type="button"
                          className="btn btn-outline-primary font-weight-bolder"
                          style={{width: "125px", height: "45px"}}
                          onClick={() =>
                            this.setState({
                              showAddMenuItemModal: true,
                            })
                          }
                        >
                          <i className="flaticon2-plus"></i>
                          Add Item
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <AddMenuItemModal
          show={showAddMenuItemModal}
          passcode={this.props.passcode}
          categoryId={this.props.categoryId}
          menuId={this.props.menuId}
          menuCategoryId={this.props.menuCategoryId}
          onUpdated={(menuItem) => {
            this.table.current.refresh();
            this.setState({
              showAddMenuItemModal: false,
              menuItem: null,
            });
          }}
          onHide={() =>
            this.setState({
              showAddMenuItemModal: false,
              menuItem: null,
            })
          }
        />

        <EditMenuItemModal
          show={showEditMenuItemModal}
          data={selectedItem}
          passcode={this.props.passcode}
          onUpdated={() => {
            this.table.current.refresh();

            this.setState({
              showEditMenuItemModal: false,
              selectedItem: null,
            });
          }}
          onHide={() =>
            this.setState({ showEditMenuItemModal: false, selectedItem: null })
          }
        />
      </>
    );
  }
}

MenuItemsTable.defaultProps = {
  title: "Menu Items",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(MenuItemsTable);
