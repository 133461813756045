import React from "react";

import { withRouter } from "react-router-dom";

import Currency from "../../../utils/Currency";

class OrdersSubTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
    };
  }

  _renderOrderDetails() {
    const { order } = this.state;

    return (
      <>
        <div className="datatable datatable-default datatable-primary datatable-scroll datatable-loaded ml-8">
          <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: 0, width: 0 }}
            />
          </div>
          <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: 0, height: 0 }}
            />
          </div>
          <table
            className="datatable-table"
            style={{ display: "block", maxHeight: 400 }}
          >
            <thead className="datatable-head">
              <tr className="datatable-row">
                <th
                  data-field="Product"
                  className="datatable-cell datatable-cell-sort datatable-cell-sorted"
                  data-sort="asc"
                >
                  <span style={{ width: 250 }}>
                    Product
                    <i className="flaticon2-arrow-up" />
                  </span>
                </th>
                <th
                  data-field="Quantity"
                  className="datatable-cell datatable-cell-sort"
                >
                  <span style={{ width: 200 }}>Quantity</span>
                </th>
                <th
                  data-field="Price"
                  className="datatable-cell datatable-cell-sort"
                >
                  <span style={{ width: 200 }}>Price</span>
                </th>
                <th
                  data-field="Sale Price"
                  className="datatable-cell datatable-cell-sort"
                >
                  <span style={{ width: 100 }}>Total</span>
                </th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: 359 }} className="datatable-body ps ">
              {order?.data?.items?.map((orderItem) => {


                let total = (orderItem?.price_sale ? orderItem?.price_sale : orderItem?.price) *
                orderItem?.quantity

       
                return (
                  <tr data-row={0} className="datatable-row" key={orderItem.id}>
                    <td
                      className="datatable-cell-sorted datatable-cell"
                      data-field="Product"
                      aria-label={orderItem?.item?.title}
                    >
                      <span style={{ width: 250 }}>
                        {orderItem?.item?.title}
                      </span>
                    </td>
                    <td
                      data-field="Quantity"
                      aria-label={orderItem?.quantity}
                      className="datatable-cell"
                    >
                      <span style={{ width: 200 }}>{orderItem?.quantity}</span>
                    </td>
                    <td
                      data-field="Price"
                      aria-label={orderItem?.price}
                      className="datatable-cell"
                    >
                      <span style={{ width: 200 }}>
                        {orderItem?.price_sale ? (
                          <div>
                            <span style={{ textDecoration: "line-through" }}>
                              {Currency.format(orderItem?.price)}
                            </span>{" "}
                            {Currency.format(orderItem?.price_sale)}
                          </div>
                        ) : (
                          Currency.format(orderItem?.price)
                        )}
                      </span>
                    </td>
                    <td
                      data-field="Total"
                      className="datatable-cell"
                    >
                      <span style={{ width: 100 }}>
                      {Currency.format(total)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  render() {
    const { order } = this.state;

    return (
      <>
        {!order && (
          <div className="text-center text-dark-75 font-weight-bold">
            Oops, no orders found...{" "}
          </div>
        )}
        {this._renderOrderDetails()}
      </>
    );
  }
}

export default withRouter(OrdersSubTable);
