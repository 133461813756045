import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import CustomButton from "../../CustomButton";

export default class AddCompanyInformation extends Component {
  constructor(props) {
    super(props);

    this.state = { stripeData: null, data: null };
  }

  _goToStripeUrl() {
    let company = AuthManager.currentUser?.company;

    this.setState({
      isLoading: true,
    });

    Backend.getStripeUrl(company)
      .then((stripeData) => {
        this.setState({ stripeData, isLoading: false });
        window.location = stripeData.connect_url;
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-8">
              <div className="pb-5" data-wizard-type="step-content">
                <h6 className="mb-10 font-weight-bold">
                  We take business safety seriously. Without creating a Stripe
                  account, we can’t allow payments to run to your account.
                </h6>
              </div>
              <div className="d-flex justify-content-between border-top mt-5 pt-10">
                <div className="mr-2"></div>
                <div>
                  <CustomButton
                    buttonClassName="btn btn-primary font-weight-bolder px-9 py-4"
                    dataWizardType="action-next"
                    title="Go to Stripe"
                    disabled={isLoading}
                    onClick={() => this._goToStripeUrl()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
