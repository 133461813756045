import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import AuthManager from "../utils/AuthManager";

import Aside from "./components/Aside";

import ProfileSettings from "./components/settings/profile-settings/ProfileSettings";
import PasswordSettings from "./components/settings/password-settings.js/PasswordSettings";
import BrandingSettings from "./components/settings/branding-settings/BrandingSettings";

const TAB_PROFILE_SETTINGS = {
  title: "Profile Settings",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
      <path
        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  ),
  description: "Control your basic default settings",
  component: ProfileSettings,
};

const TAB_PASSWORD_SETTINGS = {
  title: "Security",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <path
        d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M11.1750002,14.75 C10.9354169,14.75 10.6958335,14.6541667 10.5041669,14.4625 L8.58750019,12.5458333 C8.20416686,12.1625 8.20416686,11.5875 8.58750019,11.2041667 C8.97083352,10.8208333 9.59375019,10.8208333 9.92916686,11.2041667 L11.1750002,12.45 L14.3375002,9.2875 C14.7208335,8.90416667 15.2958335,8.90416667 15.6791669,9.2875 C16.0625002,9.67083333 16.0625002,10.2458333 15.6791669,10.6291667 L11.8458335,14.4625 C11.6541669,14.6541667 11.4145835,14.75 11.1750002,14.75 Z"
        fill="#000000"
      />
    </g>
  ),
  description: "Control your password settings",
  component: PasswordSettings,
};

const TAB_BRANDING_SETTINGS = {
  title: "Branding",
  icon: (
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <path
        d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z"
        fill="#000000"
        fill-rule="nonzero"
        opacity="0.3"
      ></path>
      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="2"></circle>
      <path
        d="M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
    </g>
  ),
  description: "Change your company branding",
  component: BrandingSettings,
};

const TABS = [
  TAB_PROFILE_SETTINGS,
  TAB_PASSWORD_SETTINGS,
  TAB_BRANDING_SETTINGS,
];

export class Settings extends Component {
  constructor(props) {
    super(props);

    let data = AuthManager.currentUser;

    let tabs = TABS;

    this.state = {
      tabs,
      data,
      activeTabIndex: this._getActiveTabIndex(tabs),
      isLoading: false,
    };
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

  render() {
    const { tabs, activeTabIndex, isLoading, data } = this.state;

    let activeTab = tabs[activeTabIndex];
    let TabComponent = activeTab.component;

    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="first"
                  data-wizard-clickable="false"
                >
                  <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    {" "}
                    <Aside
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onClick={(tab, index) => {
                        this.setState(
                          {
                            activeTabIndex: index,
                          },
                          () => {
                            this.props.history.push(tab.url);
                          }
                        );
                      }}
                    />
                  </div>                
                </div>
                <TabComponent
                    data={data}
                    onUpdated={(data) => this.setState({ data })}
                    isLoading={isLoading}
                  />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Settings);
