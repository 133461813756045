import React, { Component } from "react";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationFormat from "../../../utils/LocationFormat";
import General from "../../../utils/General";

export default class StoresDefaultCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: props.store,
      activeVenueId: AuthManager.currentUser?.active_venue,
    };
  }

  _makeVenueActive() {
    const { store } = this.state;

    this.setState({ isLoading: true });

    Backend.makeVenueActive(store.id)
      .then((venue) => {
        this.setState({ venue, isLoading: false });
        Notify.success("Venue changed to active.");
        this.props.onUpdated();
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _renderAddress(address) {
    return (
      <>
        {address ? LocationFormat.fullAddress(address) : "Address not entered."}
      </>
    );
  }

  render() {
    const { store, activeVenueId } = this.state;

    return (
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="card card-custom gutter-b card-stretch">
          <div className="card-body text-center pt-4 ribbon ribbon-clip ribbon-right">
            {store.id === activeVenueId ? (
              <div className="ribbon-target" style={{ top: 12 }}>
                <span className="ribbon-inner bg-warning" />
                Active
              </div>
            ) : (
              ""
            )}

            <div className="mt-7">
              <div className="symbol symbol-100">
                <div
                  className="symbol-label"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                >
                  <h1 className="m-0" style={{ fontSize: "30px" }}>
                    {General.getInitials(`${store?.title}`)}
                  </h1>
                </div>
              </div>
            </div>

            <div className="my-2">
              <a
                href="javascript:;"
                className="text-dark font-weight-bold text-hover-primary font-size-h4"
              >
                {store?.title}
              </a>
            </div>

            <span className="text-muted font-weight-bold">
              {this._renderAddress(store?.address)}
            </span>

            <div className="mt-9 mb-6">
              <a
                href={
                  store.id === activeVenueId
                    ? `/manage-stores/${this.props?.vendorId}/stores/${store?.id}/orders`
                    : "javascript:;"
                }
                className="btn btn-outline-primary btn-rounded w-100 mb-3"
                onClick={() =>
                  store.id === activeVenueId ? "" : this._makeVenueActive()
                }
              >
                {store.id === activeVenueId ? "View Orders" : "Make Active"}
              </a>
              <a
                href={`/manage-stores/${this.props?.vendorId}/stores/${store?.id}`}
                className="btn btn-primary btn-rounded w-100"
              >
                Settings
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
