import React, { Component } from "react";

import PastOrdersTable from "./components/tables/PastOrdersTable";

export default class PastOrders extends Component {
  render() {
    return (
      <div className="container">
     <PastOrdersTable showOrdersSubTable={true} />
      </div>
    );
  }
}
