import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../utils/LocationFormat";
import Currency from "../../../utils/Currency";
import General from "../../../utils/General";

import BaseTable from "./BaseTable";

const STATUS_FILTERS = {
  name: {
    api: "statuses",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Looking for Driver",
      value: "looking_for_driver",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ],
};

const DELIVERY_STATUS_FILTERS = {
  name: {
    api: "delivery_statuses",
    display: "Delivery Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Out For Delivery",
      value: "out_for_delivery",
    },
    {
      label: "Failed",
      value: "failed",
    },
    {
      label: "Returned",
      value: "returned",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ],
};

class StoreOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return <span>{address ? LocationFormat.fullAddress(address) : "-"}</span>;
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ORDER NO.</strong>,
        id: "id",
        width: 95,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let orderNumber = `${order?.id}`;

          if (!orderNumber) {
            return "-";
          }

          return (
            <>
              <span class="text-dark-75 line-height-sm">{orderNumber}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        sortable: false,
        width: 130,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (order.status === "accepted") {
            labelClass = "label-light-success";
            status = "Accepted";
          } else if (order.status === "pending") {
            labelClass = "label-dark-75";
            status = "Pending";
          } else if (order.status === "looking_for_driver") {
            labelClass = "label-light-info";
            status = "Looking For Driver";
          } else if (order.status === "rejected") {
            labelClass = "label-danger";
            status = "Rejected";
          }

          if (!status) {
            return "-";
          }

          return (
            <span className={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">DELIVERY STATUS</strong>,
        id: "delivery_status",
        sortable: false,
        width: 130,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let deliveryStatus = "";

          if (order.delivery_status === "delivered") {
            labelClass = "label-light-success";
            deliveryStatus = "Delivered";
          } else if (order.delivery_status === "pending") {
            labelClass = "label-light-info";
            deliveryStatus = "Pending";
          } else if (order.delivery_status === "failed") {
            labelClass = "label-dark-75";
            deliveryStatus = "Failed";
          } else if (order.delivery_status === "returned") {
            labelClass = "label-danger";
            deliveryStatus = "Returned";
          } else if (order.delivery_status === "out_for_delivery") {
            labelClass = "label-warning";
            deliveryStatus = "Out For Delivery";
          }

          if (!deliveryStatus) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {deliveryStatus}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DELIVERY ADDRESS</strong>,
        id: "address",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let address = order?.data?.customer_address;

          if (!address) {
            return "-";
          }

          return (
            <>
              <div className="ml-0">
                <span>{this._renderAddress(address)}</span>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">CUSTOMER NAME</strong>,
        id: "customer.user.first_name",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let customer = "";

          if (order?.customer) {
            customer = `${General.toTitleCase(
              order?.customer?.user?.first_name
            )} ${General.toTitleCase(order?.customer?.user?.last_name)}`;
          } else {
            customer = "-";
          }

          return (
            <>
              <span>{customer}</span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">CUSTOMER PHONE NUMBER</strong>
        ),
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let customerPhoneNumber = order.customer.user?.phone_number;

          let countryCode = order.customer.user.phone_country_code;

          if (!customerPhoneNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {countryCode && countryCode}
                {customerPhoneNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">DRIVER PHONE NUMBER</strong>
        ),
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let driverPhoneNumber = order.driver?.user.phone_number;

          let countryCode = order.driver?.user.phone_country_code;

          if (!driverPhoneNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">
                {countryCode && countryCode}
                {driverPhoneNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">COST</strong>,
        id: "price",
        sortable: false,
        width: 90,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let cost = order?.data?.items?.reduce((sum, item) => {
            const updatedPrice =
              sum +
              (item?.price_sale ? item?.price_sale : item?.price) *
                item?.quantity;

            return updatedPrice;
          }, 0);

          if (!cost) {
            return "-";
          }

          return (
            <>
              <span>{Currency.format(cost)}</span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">ORDER DATE / TIME</strong>
        ),
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let createdAt = `${moment(order?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!createdAt) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 line-height-sm">{createdAt}</span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <div className="col">
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}?venue_id=${this.props.match.params?.venueId}`}
          
          noDataMessage={"No orders found"}
          columns={columns}
          filters={[STATUS_FILTERS, DELIVERY_STATUS_FILTERS]}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
            
          }}
          
        />
      </div>
    );
    
  }
}

StoreOrdersTable.defaultProps = {
  title: "All Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(StoreOrdersTable);
