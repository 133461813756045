import runtimeEnv from "@mars/heroku-js-runtime-env";

    const env = runtimeEnv();

    window.Api = {};
    
    window.Api.Base                        = env.REACT_APP_API_BASE;
    
    window.Api.User = {};
    
    window.Api.User.Base                    = window.Api.Base + "/user";
    
    window.Api.User.Login                   = window.Api.User.Base + "/login";
    window.Api.User.Logout                  = window.Api.User.Base + "/logout";
    window.Api.User.Info                    = window.Api.User.Base + "/info";
    window.Api.User.RequestResetPassword    = window.Api.User.Base + "/request-reset-password";
    window.Api.User.ResetPassword           = window.Api.User.Base + "/reset-password";
    window.Api.User.RefreshToken            = window.Api.User.Base + "/refresh-token";

    window.Api.Venues                       = window.Api.Base + "/venues";
    window.Api.Orders                       = window.Api.Base + "/orders";
    window.Api.Items                        = window.Api.Base + "/items";
    window.Api.Companies                    = window.Api.Base + "/companies";
    window.Api.CompanyMembers               = window.Api.Base + "/company-members";
    window.Api.Staff                        = window.Api.Base + "/staff";
    window.Api.Categories                   = window.Api.Base + "/categories";
    window.Api.MenuCategories               = window.Api.Base + "/menu-categories";
    window.Api.Menus                        = window.Api.Base + "/menus";
    window.Api.MenuItems                    = window.Api.Base + "/menu-items";
    window.Api.MenuItemsV2                    = window.Api.Base + "/menu-items-v2";
    window.Api.StripeConnect                = window.Api.Base + "/stripe-connect";

    
    window.Api.Images                       = window.Api.Base + "/images";
    
    window.Api.StripePublishableKey         = env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    
    window.Api.CloudFrontBaseUrl            = env.REACT_APP_CLOUDFRONT_BASE_URL




   


