import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";
import Event from "../../../../utils/Event";

import CustomButton from "../../CustomButton";
import PhoneInput from "../../PhoneInput";

import { USER_UPDATED } from "../../../../constants/Events";

export default class ProfileSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: General.clone(props.data),
      isLoading: props.isLoading,
    };
  }

  _handleCompanyMemberChange(e) {
    let { data } = this.state;

    const key = e.target.name;
    const value = e.target.value;
    data.user[key] = value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { first_name, last_name, email, phone_number } = data.user;

    let error = null;

    if (!first_name) {
      error = "Please enter a valid first name";
    } else if (!last_name) {
      error = "Please enter a valid last name";
    } else if (!email) {
      error = "Please enter a valid email";
    } else if (!phone_number) {
      error = "Please enter a valid phone number";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateCompanyMemberAccount() {
    const { data } = this.state;

    let message = "Your details have been updated successfully.";

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateCompanyMemberAccount(data)
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
        AuthManager.currentUser.admin = data;
        this.props.onUpdated(data);
        Event.emit(USER_UPDATED);
        Notify.success(message);
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <div className="row">
          <div className="offset-xxl-2 col-xxl-8">
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_form"
            >
              <div
                className="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 className="mb-10 font-weight-bolder text-dark">
                  Profile Settings
                </h4>

                <div className="form-group row">
                  <label className="col-4 col-form-label">Contact Name</label>
                  <div className="col-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter first name"
                      name="first_name"
                      value={data?.user.first_name}
                      onChange={(e) => {
                        this._handleCompanyMemberChange(e);
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter last name"
                      name="last_name"
                      value={data?.user.last_name}
                      onChange={(e) => {
                        this._handleCompanyMemberChange(e);
                      }}
                    />
                  </div>
                </div>

                <PhoneInput
                  value={data?.user.phone_number}
                  onChange={(e) => this._handleCompanyMemberChange(e)}
                />             
                <div className="form-group row">
                  <label className="col-4 col-form-label">Email Address</label>
                  <div className="col-8 mb-4">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={data?.user.email}
                      disabled={data?.user.email}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label" />
                  <div className="col-9 mb-4 text-right">
                    <CustomButton
                      buttonClassName="btn btn-primary text-uppercase"
                      title="Save Changes"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        this._updateCompanyMemberAccount();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div />
              <div />
              <div />
              <div />
              <div />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
