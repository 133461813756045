import React, { Component } from "react";

import moment from "moment";

import Timer from "react-compound-timer";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import LocationFormat from "../../../utils/LocationFormat";

import { Oval } from "react-loader-spinner";

export default class OrdersDefaultCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
      isLoading: false
    };
  }

  _acceptOrder() {
    const { order } = this.state;

    // Set isLoading to true and hide the card
    this.setState({ isLoading: true});


    Backend.acceptOrder(order.id)
      .then((order) => {
        order.status = "looking_for_driver";

        this.setState({ order, isLoading: false });
        this.props.onUpdated();
        window.location.reload();
        Notify.success("Order has been accepted.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false
        });
      });
  }

  _rejectRefundOrder() {
    const { order } = this.state;

    this.setState({ isLoading: true });

    Backend.rejectRefundOrder(order.id)
      .then((order) => {
        order.status = "rejected";

        this.setState({ order, isLoading: false });
        this.props.onUpdated();

        Notify.success("Order has been rejected.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _outForDelivery() {
    const { order } = this.state;

    this.setState({ isLoading: true });

    Backend.outForDelivery(order.id)
      .then((order) => {
        order.delivery_status = "out_for_delivery";

        this.setState({ order, isLoading: false });
        this.props.onUpdated();

        Notify.success("Order is out for delivery.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getTotal() {
    const { order } = this.state;

    let cost = order?.data?.items?.reduce((sum, item) => {
      const updatedPrice =
        sum +
        (item?.price_sale ? item?.price_sale : item?.price) * item?.quantity;

      return updatedPrice;
    }, 0);

    return (
      <div className="d-flex align-items-center float-right mt-lg-12 mt-8">
        <h4 className="text-primary font-weight-bolder">
          Total: {Currency.format(cost)}
        </h4>
      </div>
    );
  }

  _renderAddress(address) {
    return (
      <div className="d-flex mr-3">
        <h5 className="text-dark-75 font-size-h5 font-weight-bold mr-3">
          {address
            ? LocationFormat.fullAddress(address)
            : "Address not entered"}
        </h5>
      </div>
    );
  }

  render() {
    const { order, isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="loader-container " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Oval
            height={50}
            width={50}
            color="#510B29;"
            ariaLabel="loading"
            secondaryColor="#f56d63"
          />
        </div>
      );
    }

    let orderedItemsLength = order?.data?.items.length;

    let address = order?.data?.customer_address;

    let now = moment().valueOf();

    let createdAt = moment(order?.created_at).format("DD/MM/YYYY hh:mm A");

    let orderCreatedAt =
      now - moment(createdAt, "DD/MM/YYYY hh:mm A").valueOf();

    let receipt = `${window.Api.CloudFrontBaseUrl}${order?.receipt}`;

    return (
      <div className="card-body">
        <div className="card card-custom gutter-b">
          <div className="card-body">
            <div className="d-flex mb-4">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-10">
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between flex-wrap mt-1">
                  <div className="d-flex mr-3">
                    <h5 className="text-dark-75 font-size-h5 font-weight-bold mr-3">
                      {this._renderAddress(address)}
                      <br />
                    </h5>
                  </div>

                  <div className="my-lg-0 my-3 d-xs-none">
                    {order?.status === "looking_for_driver" ||
                    order?.status === "accepted" ? (
                      <>
                        {order?.driver && (
                          <a
                            href="javascript:;"
                            className="btn btn-sm btn-primary font-weight-bolder text-uppercase w-xs-100"
                            disabled={isLoading}
                            onClick={() => this._outForDelivery()}
                          >
                            Mark As Out For Delivery
                          </a>
                        )}
                      </>
                    ) : (
                      <>
                        <a
                          href="javascript:;"
                          className="btn btn-sm secondary-bg font-weight-bolder text-uppercase mr-3"
                          disabled={isLoading}
                          onClick={() => this._acceptOrder()}
                        >
                          Accept
                        </a>
                        <a
                          href="javascript:;"
                          className="btn btn-sm secondary-bg font-weight-bolder text-uppercase mr-3"
                          disabled={isLoading}
                          onClick={() => window.open(receipt)}
                        >
                          Print
                        </a>
                        <a
                          href="javascript:;"
                          className="btn btn-sm btn-primary font-weight-bolder text-uppercase"
                          disabled={isLoading}
                          onClick={() => this._rejectRefundOrder()}
                        >
                          Reject &amp; Refund
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-between mt-1">
                  <div className="d-flex flex-column flex-grow-1 pr-8">
                    <div className="d-flex flex-wrap mb-4">
                      <a
                        href="javascript:;"
                        className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <i className="flaticon2-open-box mr-2 font-size-lg" />
                        {orderedItemsLength === 1
                          ? `${orderedItemsLength} Item`
                          : `${orderedItemsLength} Items`}
                      </a>
                      <a
                        href="javascript:;"
                        className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <i className="flaticon2-user-outline-symbol mr-2 font-size-lg" />
                        {order?.customer?.user?.first_name}{" "}
                        {order?.customer?.user?.last_name}
                      </a>

                      {order?.customer && (
                        <a
                          href="javascript:;"
                          className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i className="flaticon-support mr-1"></i>
                          {order.customer.user.phone_country_code}
                          {order.customer.user.phone_number}
                        </a>
                      )}

                      {order?.status !== "pending" && (
                        <a
                          href="javascript:;"
                          className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i className="text-dark-50 flaticon-truck mr-1"></i>{" "}
                          {order?.driver?.user?.first_name}{" "}
                          {order?.driver?.user?.last_name}
                        </a>
                      )}

                      {order?.driver && (
                        <a
                          href="javascript:;"
                          className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <i className="flaticon-support mr-1"></i>
                          {order.driver.user.phone_country_code}
                          {order.driver.user.phone_number}
                        </a>
                      )}

                      {!order?.driver?.user &&
                        (order?.status === "accepted" ||
                          order?.status === "looking_for_driver") && (
                          <span
                            className="text-muted mt-1"
                            style={{ transform: "translateX(-1rem)" }}
                          >
                            ...Looking for drivers
                          </span>
                        )}

                      {(order?.status === "looking_for_driver" ||
                        order?.status === "accepted") && (
                        <a
                          href="javascript:;"
                          className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 mt-1"
                        >
                          <span className="text-muted">Code: </span>

                          {order?.driver_verification_number}
                        </a>
                      )}

                      <a className="mt-1" href="javascript:;">
                        <i className="flaticon2-hourglass font-size-lg mr-2" />
                        <Timer
                          initialTime={orderCreatedAt}
                          startImmediately={true}
                        >
                          <Timer.Hours />
                          h:
                          <Timer.Minutes />
                          min:
                          <Timer.Seconds />s
                        </Timer>
                      </a>
                    </div>
                    <h5 className="text-dark font-weight-bolder">Items</h5>
                    {order?.data?.items.map((orderItem) => {
                      return (
                        <ul key={orderItem.id}>
                          <li>
                            <span className="mr-1">{orderItem.quantity}</span>x{" "}
                            {General.toTitleCase(orderItem?.item.title)}{" "}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                  {this._getTotal()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
