import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import General from "../utils/General";

import CreateAccount from "./CreateAccount";

import DashboardApp from "./DashboardApp";

export default class App extends React.Component {
  componentDidMount() {
    let step = General.getStep();

    if (step != null && window.location.pathname != "/create-account") {
      window.location = "/create-account";
    }
  }

  render() {
    return (
      <>
        <Switch>
          <Route exact path="/create-account" component={CreateAccount} />
          <Route path="/" component={DashboardApp} />
        </Switch>
      </>
    );
  }
}
