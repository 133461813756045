import React, { Component } from "react";

import CreateAccountSettings from "./CreateAccountSettings";

import General from "../utils/General";

import Footer from "./components/Footer";

export default class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (General.getStep() == null) {
      window.location = "/";
    }
  }

  render() {
    return (
      <>
        <div className="container mt-5 container-center">
          <div className="row">
            <div className="col-xl-12" >
              <div className="card card-custom gutter-b">
                <div className="card-body d-flex p-0 container-custom-width">
                  <div
                    className="flex-grow-1 p-10 card-rounded flex-grow-1 bgi-no-repeat"
                    style={{
                      backgroundPosition: "calc(100% + 9rem) center",
                      backgroundSize: "40% auto",
                      backgroundImage: "url(assets/media/svg/cocktail.svg)",
                    }}
                  >
                    <h2 className="text-dark pb-5 font-weight-bolder">
                      Welcome to Clinks!
                    </h2>
                    <p className="text-dark-50 pb-5 font-size-h5">
                      Thank you for joining Clinks! Now you have your account,
                      let's set up your profile. We will have you fully set up
                      in no time, <br />
                      all you have to do is create your profile and add your
                      menu. This forms contents are going to be visible to your
                      customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8 container-custom-width">
            <CreateAccountSettings />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
