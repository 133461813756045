import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import { orders } from "./components/Header";
import Header from "./components/Header";
import Footer from "./components/Footer";

import MobileHeader from "./components/MobileHeader";
import UserPanel from "./components/UserPanel";
import ScrollTop from "./components/ScrollTop";

import OutstandingOrders from "./OutstandingOrders";
import PastOrders from "./PastOrders";
import ManageMenu from "./ManageMenu";
import ManageStores from "./ManageStores";

import StoreOrderTable from "./components/tables/StoreOrderTable";

import AddStore from "./AddStore";
import StoreSettings from "./StoreSettings";

import Settings from "./Settings";

import StripeVerificationModal from "./components/modals/StripeVerificationModal";
import boopSfx from '../assets/sound/newOrder.mp3';
import { PASSCODE_ICON_UPDATED, USER_UPDATED } from "../constants/Events";
import Notify from "../utils/Notify";


// function Play(){
//   let msc = new Audio(boopSfx);
//   msc.play();
// }

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      company: AuthManager.currentUser.company,
      orders: null,
      showSuccessModal: true,
      
      
    };
  }

  
  // componentDidMount() {
  //   // Event.on(USER_UPDATED, () =>
  //   //   this.setState({
  //   //     currentUser: AuthManager.currentUser.user,
  //   //   })
  //   // );

  //   // Event.on(PASSCODE_ICON_UPDATED, (value) => {
  //   //   this.setState({
  //   //     passcode: value,
  //   //   });
  //   // });

    

  //   this.refreshOrders = setInterval(() => {
  //     this._getOrders();
      
  //   }, 30000);
  // }

  // componentWillUnmount() {
  //   // Event.off(USER_UPDATED);

  //   // Event.off(PASSCODE_ICON_UPDATED);

  //   clearInterval(this.refreshOrders);
  // }


  // componentDidMount() {
  //   let msc = new Audio(boopSfx);


  //   setTimeout(function(){
  //      msc.play();
  //      alert("new Order For You");
  //   },6000);
   
  // }
  
  // _getOrders() {
  //   // if (orders == null ){
  //   //   let order_count = 0
  //   // }
  //   // else{
  //   //   let order_count = orders.total_count -1
  //   // }
  //   // this.setState({ isLoading: true });
    
    
  //   Backend.getOrders()
  //     .then((orders) => {
        
        // if (order_count < orders.total_count){
        
        //   console.log('fdskjghskladd');
        //   let msc = new Audio(boopSfx);
        //   // let orderNumber = `${order?.id}`;
        //   msc.play();
          
        // }  
        // if (orders.results != {}){
        
        //   console.log('fdskjghskladd');
        //   let msc = new Audio(boopSfx);
        //   // let orderNumber = `${order?.id}`;
        //   msc.play();
          
        // }  
        
        
  //       this.setState({ orders, isLoading: false });
  //     })
  //     .catch((error) => {
  //       Notify.error(error.message);
  //       this.setState({
  //         isLoading: false,
  //       });
  //     });
  // }
  
  // _getOrders() {
  //   console.log('dfghjhk')
  //   this.setState({ isLoading: true });

  //   Backend.getOrders()
    
      // .then((orders) => {
      //   let counter = 0
      //   let msc = new Audio(boopSfx);
        
        
      //   if (orders.total_count > counter){
      //     counter++
          
      //     let msc = new Audio(boopSfx);
      //     // let orderNumber = `${order?.id}`;
      
          
      //   }
      //   this.setState({ orders, isLoading: false });
      // })
      
      
  // }
  
  
  // componentDidMount() {
    
  //   // let all_orders = AuthManager.orders()
  //   let msc = new Audio(boopSfx);
    
    
  //   // console.log(all_orders.total_count);
    
  //   setTimeout(function(){
  //     Backend.getOrders();
  //     //alert(orders.total_count);
  //     msc.play();
  //   },6000);
  // }
  // }
  
  // componentDidMount() {
  //   let msc = new Audio(boopSfx);
  //   // let orderNumber = `${order?.id}`;

  //   setTimeout(function(){
  //      msc.play();
  //      alert("new Order For You");
  //   },6000);
   
  // }
  
  render() {
    const { company, showSuccessModal } = this.state;

    let isCompanyStripeConnected =
      company.stripe_connected &&
      company.stripe_verification_status === "unverified";

    return (
      <>
        <MobileHeader />
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />
              
              
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div>
                
                  <Switch>
                    <Route exact path="/" component={OutstandingOrders} />
                    <Route exact path="/past-orders" component={PastOrders} />
                    <Route exact path="/menus/:menuId" component={ManageMenu} />
                    <Route
                      exact
                      path="/manage-stores"
                      component={ManageStores}
                    />
                    <Route
                      exact
                      path="/manage-stores/:vendorId/add-store"
                      component={AddStore}
                    />
                    <Route
                      exact
                      path="/manage-stores/:vendorId/stores/:venueId"
                      component={StoreSettings}
                    />
                    <Route
                      exact
                      path="/manage-stores/:vendorId/stores/:venueId/orders"
                      component={StoreOrderTable}
                    />
                    <Route path="/settings" component={Settings} />
                    <Redirect to="/404" />
                  </Switch>
                </div>
              </div>
              <Footer />
            </div>
            <UserPanel />
          </div>
          <ScrollTop />
        </div>

        {isCompanyStripeConnected && (
          <StripeVerificationModal
            company={company}
            show={showSuccessModal}
            onHide={() =>
              this.setState({
                showSuccessModal: false,
              })
            }
          />
        )}
      </>
    );
  }
}