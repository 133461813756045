import React from "react";

import AuthManager from "../utils/AuthManager";
import Email from "../utils/Email";
import Notify from "../utils/Notify";
import General from "../utils/General";

const MODE_SIGNIN = "signin";
const MODE_FORGOT_PASSWORD = "forgot_password";
const MODE_RESET_PASSWORD = "reset_password";

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      isLoading: false,
      signUpStep: 1,
      mode: MODE_SIGNIN,
      agree: false,
    };
  }

  _isLoginFormValid() {
    let { mode, email, password, agree } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode == MODE_SIGNIN) {
      if (!password) {
        this.setState({ error: "Please enter your password." });
        return false;
      }

      if (!agree) {
        this.setState({
          error: "Please accept Terms & Conditions and Privacy Policy.",
        });
        return false;
      }
    }
  }

  _isFormValid() {
    let { email, password, confirm_password, mode, code, agree } = this.state;

    if (!Email.isValid(email)) {
      this.setState({ error: "Please enter a valid email address" });
      return false;
    }

    if (mode == MODE_SIGNIN) {
      if (!password) {
        this.setState({ error: "Please enter your password." });
        return false;
      }

      if (!agree) {
        this.setState({
          error: "Please accept Terms & Conditions and Privacy Policy.",
        });
        return false;
      }
    }

    if (mode == MODE_RESET_PASSWORD) {
      if (!code) {
        this.setState({ error: "Please enter a valid code" });
        return false;
      }
      if (password.length < 6) {
        this.setState({ error: "Password must be at least 6 characters" });
        return false;
      }
      if (password != confirm_password) {
        this.setState({ error: "Passwords do not match" });
        return false;
      }
    }

    return true;
  }

  _handleLogInClicked(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password } = this.state;

    this.setState({ isLoading: true });

    AuthManager.login(email, password, false)
      .then(() => {
        sessionStorage.removeItem("passcodeKey")

        let step = General.getStep();
        let path = step != null ? "/create-account" : "/";

        window.location = path;
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleRequestResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, confirm_password } = this.state;

    this.setState({ isLoading: true });

    AuthManager.requestResetPassword(email)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_RESET_PASSWORD,
          password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _handleResetPassword(e) {
    e.preventDefault();

    if (!this._isFormValid()) {
      return;
    }

    let { email, password, code } = this.state;

    this.setState({ isLoading: true });
    AuthManager.resetPassword(email, password, code)
      .then(() => {
        this.setState({
          isLoading: false,
          mode: MODE_SIGNIN,
          email: "",
          password: "",
          confirm_password: "",
        });
        Notify.success("Your password has been reset");
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
      });
  }

  _renderError() {
    let { error } = this.state;

    if (!error) {
      return null;
    }

    return (
      <div
        className="kt-alert kt-alert--outline alert alert-danger alert-dismissible"
        role="alert"
      >
        <span>{error}</span>
      </div>
    );
  }

  render() {
    let { email, password, confirm_password, isLoading, mode, code, agree } =
      this.state;

    return (
      <>
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-13 mx-auto">
            <img src="assets/media/logos/clinks.svg" height="80px" />
            <div className="d-flex flex-column-fluid flex-center">
              <div
                className="login-form login-signin"
                style={{
                  display: mode === MODE_SIGNIN ? "block" : "none",
                }}
              >
                <form
                  className="form"
                  noValidate="novalidate"
                  id="kt_login_signin_form"
                >
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Welcome!
                    </h3>

                    {this._renderError()}

                    <span className="text-muted font-weight-bold font-size-h4">
                      Please enter your login details to continue
                    </span>
                  </div>

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Email
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Password
                      </label>
                      <a
                        href="javascript:;"
                        className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                        id="kt_login_forgot"
                        onClick={() =>
                          this.setState({
                            email: "",
                            mode: MODE_FORGOT_PASSWORD,
                            error: null,
                          })
                        }
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <input
                      className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                      type="password"
                      placeholder="Password"
                      autoComplete="off"
                      name="password"
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div
                    className="form-group mt-5"
                    onClick={(e) => this.setState({ agree: e.target.checked })}
                  >
                    <label class="checkbox mb-0">
                      <input type="checkbox" name="agree" />I agree with the
                      <a href="https://clinks.ie/terms-of-use" target="_blank">
                        {" "}
                        <strong>Terms & Conditions</strong>
                      </a>{" "}
                      and
                      <a
                        href="https://clinks.ie/privacy-policy"
                        target="_blank"
                      >
                        {" "}
                        <strong>Privacy Policy</strong>
                      </a>
                      .<span></span>
                    </label>
                  </div>

                  <div className="pb-lg-0 pb-5">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 w-100"
                      onClick={(e) => {
                        this._handleLogInClicked(e);
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading... " : "Log In"}
                    </button>
                  </div>
                
                </form>
              </div>

              <div
                className="login-form login-forgot"
                style={{
                  display: mode === MODE_FORGOT_PASSWORD ? "block" : "none",
                }}
              >
                <form className="form" noValidate id="kt_login_forgot_form">
                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      Forgotten Password ?
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      Enter your email to reset your password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) =>
                        this.setState({ email: e.target.value, error: null })
                      }
                    />
                  </div>
                  <div className="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      type="button"
                      id="kt_login_forgot_submit"
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => this._handleRequestResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading... " : "Submit"}
                    </button>
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-outline-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          email: "",
                          password: "",
                          mode: MODE_SIGNIN,
                          error: null,
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>

              <div
                className="login-form login-reset"
                style={{
                  display: mode === MODE_RESET_PASSWORD ? "block" : "none",
                }}
              >
                <form className="form" novalidate="novalidate">
                  <div className="pb-13 pt-lg-0 pt-5 mt-20">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg ">
                      Reset Password
                    </h3>
                    <p className="text-muted font-weight-bold font-size-h4">
                      A verification code was sent to your email, please enter
                      it below along with your new password
                    </p>
                  </div>

                  {this._renderError()}

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Code
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      placeholder="Code"
                      name="code"
                      autoComplete="off"
                      value={code}
                      onChange={(e) =>
                        this.setState({ code: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Password
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      placeholder="Password"
                      name="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value, error: null })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Confirm Password
                    </label>
                    <input
                      className="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      autoComplete="new-password"
                      value={confirm_password}
                      onChange={(e) =>
                        this.setState({
                          confirm_password: e.target.value,
                          error: null,
                        })
                      }
                    />
                  </div>

                  <div className="form-group d-flex flex-wrap pb-lg-0">
                    <button
                      className="btn btn-outline-dark font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          email: "",
                          password: "",
                          mode: MODE_SIGNIN,
                          error: null,
                        });
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                      onClick={(e) => this._handleResetPassword(e)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading... " : "Update Password"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="login-aside d-flex flex-column flex-row-auto"
            style={{
              backgroundSize: "cover",
              backgroundImage: "url(assets/media/bg/login.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            <div className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center" />
          </div>
        </div>
      </>
    );
  }
}
