import React, { Component } from "react";

import OrdersDefaultCard from "./OrdersDefaultCard";

export default class OutstandingOrdersCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order,
    };
  }

  render() {
    const { order } = this.state;

    return (
      <OrdersDefaultCard
        order={order}
        onUpdated={() => this.props.onUpdated()}
      />
    );
  }
}
